import * as React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SelectInput from '../InputFields/SelectInput/SelectInput';
import CheckButton from '../Buttons/CheckButton/CheckButton';
import CircularWithValueLabel from '../Progress/CircularProgress/CircularProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "./Table.scss";
import ImageButton from '../Buttons/ImageButton/ImageButton';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckListRowNew from './CheckListRowNew/CheckListRowNew';
import { useAdditionalCheckList } from '../../../hooks/useAdditionalCheckLists';
import { useTestsPerFloor } from '../../../hooks/useTestsPerFloor';
// import CheckListRow from '../../updatePopups/reviewPopup/parts/systemsCheckListSection/parts/checkListRow';



const StyledTableCell = withStyles({
    root: {
        // borderBottom: "1px solid #CACBCC",
        // borderTop: "1px solid #CACBCC",
       
        // border: '1px solid red',
        padding: '0px 10px',
        "&:last-child": {
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',

        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",

        }
    },
})(TableCell);

const StyledTableHeaderCell = withStyles({
    root: {
        paddingTop: '10px',
        paddingBottom: '10px', 
        borderTop: "1px solid #CACBCC",
        "&:last-child": {
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        }
    },
})(TableCell);

const StyledTableContainer = withStyles({
    root: {
        border: 'none',
        padding: '0px',
        border: '5px solid red'
    }
}) (TableContainer);



const useStyles = makeStyles((theme) => ({
    tableContainer: {
        border: 'none',
        background: 'transparent'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0px 10px',
        border: 'none',
    },
    tableHead: {
        backgroundColor: '#EEEEEE',
    },
    tableRow: {
        maxHeight: '20px'
    },
    headerCell: {
        fontSize: '14px',
        fontWeight: 800,
        color: '#455768',
        fontFamily: 'Rubik !important',
    },
    bodyCell: {
        fontSize: '14px',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        maxWidth: '100px',
        maxHeight: '100px',
        background: '#fff',
    },
    input: {
        marginTop: '2px',
        width: '100%',
        padding: '6px',
        border: '1px solid #CACBCC',
        borderRadius: '5px',
        color: '#CACBCC',
    },
    selectInputContainer: {
        position: 'absolute',
        top: '12px',
        left: '0px'
    }

}));
let inputData = { label: '', placeholder: '', width: '70px', height: '20px', border: '1px dashed #CACBCC', onChange: function () { } };
let selectionInputData = { label: '', placeholder: 'Jakob Baptista', width: '70px', height: '30px', border: 'none', selectOptions: [{ id: 1, name: 'תקין' }, { id: 2, name: 'לא תקין' }, { id: 3, name: 'לא קיים / לא ניתן לבצע בדיקה' }], handleChange: function () { } };
let checkboxInput = { name: '', with: '40px', border: 'none', isBoxShadow: false, background: 'transparent' };

export default function CheckListTable(props) {
    let { data, orderId, process, parentSystemId } = props;
    console.log("process-----", process)
    console.log("data-----", data.rows, process)
    console.log("parentSystemId ------", parentSystemId)
    
    data.rows = data?.rows.filter((row) => row?.selected_process.toString() === process.processId.toString() && row?.selected_upper_layer_system.toString() === parentSystemId.toString())
    const classes = useStyles();

    // temporarily variable
    let union_check_list = true;
    let actual_system_id = data.actualSystemId;

    const { setRowTitle} = useAdditionalCheckList(orderId, actual_system_id, union_check_list)
    const { getTestsPerFloor} = useTestsPerFloor(actual_system_id)

    function setValueHandler(rowIdx, colIdx, value) {

    }
    function uploadPhotoHandler() {

    }
    function removeFileHandler() {

    }

    // const setValueHandler = (rowIdx, colIdx, value) => {
    //     clear_checklist_flag.current.indexs.push({ rowIdx, colIdx })
    //     const systemChecklistSection = { ...systemsCheckListData };
    //     systemChecklistSection.data[rowIdx].values[colIdx].value = value;
    //     const selectedValue = systemChecklistSection.data[rowIdx].values[colIdx].value
    //     if (colIdx === 0 && selectedValue && selectedValue !== PROPER) {
    //         // if first colum is not proper and row has show stopper set show stopper NON_CRIRTICAL_FAULT_SHOW_STOPPER = 'תקלה לא קריטית: לתקן בהקדם'
    //         systemChecklistSection.data[rowIdx].show_stopper = NON_CRIRTICAL_FAULT_SHOW_STOPPER
    //     } else if (colIdx === 1) {
    //         // for second input after good  / not good
    //         const options = systemChecklistSection.data[rowIdx].values[colIdx].options
    //         if (typeof selectedValue === 'string') {
    //             const { action: actionValue, showStopper } = findShowStopperAndAction(options, selectedValue)
    //             // set recommended action for next column
    //             systemChecklistSection.data[rowIdx].values[colIdx + 1].value = actionValue
    //             // set show stopper text for row
    //             systemChecklistSection.data[rowIdx].show_stopper = showStopper
    //         } else if (Array.isArray(selectedValue)) {
    //             const actionsArray = []
    //             const showStoppers = []
    //             selectedValue.forEach(option => {
    //                 const { action, showStopper } = findShowStopperAndAction(options, option)
    //                 action && actionsArray.push(action)
    //                 showStopper && showStoppers.push(showStopper)
    //             })
    //             const actions = actionsArray.join(',')
    //             const show_stopper = showStoppers.join(',')
    //             systemChecklistSection.data[rowIdx].values[colIdx + 1].value = actions
    //             systemChecklistSection.data[rowIdx].show_stopper = show_stopper
    //         }
    //     }
    //     updateProcessPopupStateHandler({ systemChecklistSection });
    // };
  
  
    return (
        <>
            {/* // <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
            <StyledTableContainer component={Paper} style={{ position: "sticky", top: 0, border: 'none' }} className={classes.tableContainer}>
                <Table
                    aria-label="caption table" size='small'
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {
                                data.columns?.map((col, index) => {
                                    return <StyledTableHeaderCell key={index} align="right" className={classes.headerCell}>{col.headerName}</StyledTableHeaderCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {/* </Table>
            </TableContainer>
            <TableContainer style={{ height: "400px", overflow: "auto" }}>
                <Table className={classes.table} style={{ tableLayout: "fixed" }}> */}
                    {
                        data.rows &&

                        <TableBody>
                            {data.rows.map((row, rowIdx) => {
                                let newRow = {};
                                newRow.values = row;
                                return <CheckListRowNew
                                    key={rowIdx}
                                    row={newRow.values}
                                    rowTitle={newRow.row_title}
                                    rowIdx={rowIdx}
                                    setValueHandler={setValueHandler}
                                    uploadPhotoHandler={uploadPhotoHandler}
                                    removeFileHandler={removeFileHandler}
                                    orderId={orderId}
                                    setRowTitle={setRowTitle}
                                    getTestsPerFloor={getTestsPerFloor}
                                />

                                {/* return <TableRow key={rowIdx} className={classes.tableRow}>
                                    {
                                        row.map((_rowCol, colIdx) => {
                                            if (colIdx == 6) {
                                                return <StyledTableCell size='small' className={classes.bodyCell} key={colIdx} component="td" scope="row" align="right">
                                                    <CircularWithValueLabel />
                                                </StyledTableCell>
                                            }
                                            else if (_rowCol.typeOfInput === 'read') {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                    {_rowCol.value}
                                                </StyledTableCell>
                                            }
                                            else if (typeof (_rowCol.value) == 'object' && colIdx === 5) {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                    <input className={classes.input} />
                                                </StyledTableCell>
                                            }
                                            else if (parseInt(_rowCol.typeOfInput)) {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                    <Autocomplete
                                                        className={colIdx === 1 ? classes.maxWidth : ''}
                                                        classes={{ popper: classes.styledOptionsList }}
                                                        size='small'
                                                        freeSolo
                                                        multiple={parseInt(_rowCol.typeOfInput) >= 0}
                                                        options={createOptions(_rowCol?.options)}
                                                        getOptionLabel={(option) => option && typeof option === 'string' ? option : (option?.join?.() || '')}
                                                        value={formatValue(_rowCol)}
                                                        onChange={(e, value) =>
                                                            setValueHandler(rowIdx, colIdx, value)
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                padding='dense'
                                                                {...params}
                                                                variant="standard"
                                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                                            />
                                                        )}
                                                    />
                                                </StyledTableCell>
                                            }
                                             else if (typeof (_rowCol.value) == 'checkbox') {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                    <CheckButton data={checkboxInput} />
                                                </StyledTableCell>
                                            }

                                            else if (_rowCol.value == 'text') {
                                                console.log("called")
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                    <TextField
                                                        multiline
                                                        rows={2}
                                                        placeholder={_rowCol.placeholder}
                                                        value={_rowCol.value}
                                                        onChange={(e) => setValueHandler(rowIdx, colIdx, e.target.value)}
                                                        // InputProps={{ disableUnderline: true }}
                                                    />
                                                </StyledTableCell>
                                            }

                                            return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" className={classes.bodyCell}>
                                                {_rowCol.value}
                                            </StyledTableCell>
                                        })
                                    }
                                    <StyledTableCell size='small' component="td" scope="row" align="right" className={classes.bodyCell}>
                                        <ImageButton data={'Upload photo'} isLabel={false} />
                                    </StyledTableCell>
                                </TableRow> */}
                            })}
                        </TableBody>
                    }
                </Table>
                {
                    !data.rows && <div className="table__emptyContainer"><p className='table__noItem'>There is no data</p></div>
                }
            </StyledTableContainer>
            {/* // </Paper> */}
        </>
    );
}


{/* else if (typeof (_rowCol.value) == 'object') {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" style={{ position: 'relative' }} className={classes.bodyCell}>
                                                    <div className={classes.selectInputContainer}>
                                                        <SelectInput data={selectionInputData} />
                                                    </div>
                                                </StyledTableCell> */}