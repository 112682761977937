import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import login_reducer from './login_reducer'
import orders_reducer from './orders_reducer'
import route_location from './route_reducer'
import loaderPopup_reducer from "./loaderPopup_reducer"
import csv_reducer from "./csv_reducer"
import mainPopup_reducer from "./mainPopup_reducer"
import process_reducer from "./process_reducer"
import alertPopup_reducer from "./alertPopup_reducer"
import factoriesReducer from "./factories_reducer"
import subDepartmentsReduser from "./sub_departments_reduser"
import departmentsReduser from "./departments_reduser"
import modalReducer from "./modal_reducer"
import resourcesReducer from "./resources_reducer"
import recipientReducer from "./recipient_reducer"
import orderTypeReducer from "./order_type_reducer"
import ganttTemplateReducer  from "./gantt_template_reducer"
import ganttActualReducer  from "./gantt_actual_reducer"
import adminOrdersReducer  from "./admin_orders_reducer"
import orderСategoriesReducer from "./order_categories_reducer"
import usersReduser from "./users_reduser"
import orderCollectionStagesReducer from "./order_collection_stages_reducer"
import updateStageInFinancialReportReducer from './updateOrderPaymentReducer'
import customersPageReducer from './customers_page_reducer'
import quotationReducer from './quotation-reducer'
import taskReportsReducer from './tasks-report-reducer'
import adminSystemsReducer from './admin_systems_reducer'
import reviewPopupReducer from './review_popup_reducer'
import pageInfoReducer from './page_info_reducer'
import monthResourceView from './month_resource_view'
import fileReducer from './customer/fileReducer'
import ordersReducer from './customer/orderReducer'
import quoteReducer from './customer/quoteReducer'
import customerDetailsReducer from './customer/customerDetailsReducer'
import taskReducer from './customer/taskReducer'
import customerSystemReducer from './customer/customerSystemReducer'
import generalReducers from './generalReducers'
import generalReducer from './general_reducer'

export default (history) => combineReducers({
    router: connectRouter(history),
    login: login_reducer,
    orders: orders_reducer,
    route: route_location,
    loaderPopup: loaderPopup_reducer,
    csv: csv_reducer,
    mainPopup: mainPopup_reducer,
    process: process_reducer,
    alertPopup: alertPopup_reducer,
    factories: factoriesReducer,
    subDepartments: subDepartmentsReduser,
    departments: departmentsReduser,
    modal: modalReducer,
    resources:resourcesReducer,
    recipients:recipientReducer,
    orderTypes:orderTypeReducer,
    ganttTemplate:ganttTemplateReducer,
    ganttActual:ganttActualReducer,
    adminOrders:adminOrdersReducer,
    orderСategories:orderСategoriesReducer,
    users:usersReduser,
    orderCollectionStages:orderCollectionStagesReducer,
    updateStagesInFinancialReport: updateStageInFinancialReportReducer,
    customersPage: customersPageReducer,
    quotationManagement: quotationReducer,
    taskReports: taskReportsReducer,
    adminSystems: adminSystemsReducer,
    reviewPopup: reviewPopupReducer,
    pageInfo: pageInfoReducer,
    monthResource: monthResourceView,
    files: fileReducer,
    customerOrders: ordersReducer,
    quotes: quoteReducer,
    customerDetails: customerDetailsReducer,
    tasks: taskReducer,
    customerSystems:  customerSystemReducer,
    generalReducers,
    generalReducer: generalReducer
})