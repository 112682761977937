import React, { Fragment, useEffect, useState } from 'react'
import Button from '../../Buttons/Button';
import AccordionMain from '../../AccordionRow/AccordionMain';
import AddMeasurements from '../../MobileViews/PopUps/AddMeasurements/AddMeasurements';
import AddSystemPopUp from '../../TabletView/PopUps/AddSystem/AddSystemPopUp';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import DeletePopUp from '../../TabletView/PopUps/Delete/DeletePopUp';
import '../../TabletView/FileTab/FileTab.scss'
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { systemActionsCreator } from '../../../../actions/customer/systemActions';
import TabEmptyLanding from '../../MobileViews/TabEmptyLanding/TabEmptyLanding';
import EmptyList from '../../TabletView/SystemsTab/EmptyList/EmptyList';
import "./SystemTab.scss";
import { systemTabDesktopBtn, systemTabPopUpBtn } from "./SystemTabStatic";
import { api, createHeaders } from "../../../../constants/api-urls";
import axios from "axios";


export default function SystemTab(props) {
    const { view, process, closePopup, componentFor, customer } = props;
    const [currentPopup, setCurrentPopup] = useState('add');
    const [showPopUp, setShowPopUp] = useState(false);
    const [systemId, setSystemId] = useState(null);
    const [parentSystemId, setParentSystemId] = useState(null);
    const [customersData, setCustomerData] = useState(null);
    let orderId = process && process.order_id;
    const [currentLayer, setCurrentLayer] = useState(1);
    const [isNewSystemSubmitted, setIsNewSystemSubmitted] = useState(false);
    const [addSystemActionLevel, setAddSystemLevel] = useState(1);
    const customerOrders = useSelector((state) => state);
    console.log("custoerORders", customerOrders);
    // orderId = customerOrders?.orders?.rows[0]?.id
    console.log("orderId", orderId)
    // console.log("customer", customer)
    


    const dispatch = useDispatch();
    const accordionDataTree = useSelector((state) => state.customerSystems.systemsForAccordion);
    const selectedSystem = useSelector((state) => state.customerSystems.singleSystem);
    const isShowSystemsPopup = useSelector((state) => state.customerSystems.isShowSystemsPopup);

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }

    function onClickHandler(label) {
        setShowPopUp(true);
    }

    const popupClickHandlers = {
        'Add System': (systemID, layer) => {
            console.log("asdasds1111");
            setCurrentLayer(layer +  1);
            setAddSystemLevel(layer + 1);
            setParentSystemId(systemID)
            setCurrentPopup('add');
            handleShowPopUp();
        },
        'Edit System': (systemId, layer) => {
            setCurrentPopup('edit');
            setCurrentLayer(layer)
            setSystemId(systemId);
            dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, layer }));
            handleShowPopUp();
        },
        'Delete System': (systemId, layer) => {
            setCurrentPopup('delete');
            setCurrentLayer(layer);
            setSystemId(systemId);
            handleShowPopUp();
            if (systemId && layer) {
                dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, layer }))
            }
        },
        'Add Measurements': (systemId, layer) => {
            setCurrentPopup('measurement');
            dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, layer }));
            handleShowPopUp();
        },
        'Move Up': (index, layer) => {
            if(index>0){
                dispatch(systemActionsCreator.moveSystemRow({ index, layer,  direction: 'up' }))
            }else{
                alert("can't move up")
            }
        },
        'Move Down': (index, layer) => {
            dispatch(systemActionsCreator.moveSystemRow({ index, layer,  direction: 'down' }))
        },
    }

    useEffect(() => {
        if (!customer) {
            console.log("12121212122");
            dispatch(systemActionsCreator.getSystemsByOrderId(orderId));
        }
        
        // dispatch(systemActionsCreator.getSystemsForAccordion());
    }, [dispatch, orderId])

    useEffect(() => {
        if (customer) {
            dispatch(systemActionsCreator.getSystemsByCustomer(customer));
        } 
    }, []);


    const updateSystems = () => {
        if (customer) {
            dispatch(systemActionsCreator.getSystemsByCustomer(customer));
        } 
    }

    // useEffect(() => {
    //     dispatch(systemActionsCreator.selectedSystemBySystemId({ systemId, currentLayer }))
    // }, [dispatch, systemId])

     useEffect(()=>{
         setShowPopUp(isShowSystemsPopup)
     }, [isShowSystemsPopup])


    // useEffect(() => {
    //     if (isNewSystemSubmitted) {
    //         setTimeout(() => {
    //             dispatch(systemActionsCreator.getSystemsForAccordion());
    //         }, 100)
    //     }

    // }, [isNewSystemSubmitted])

    const systemNameToDelete = selectedSystem.system_name;
    const locationDescriptionToDelete = selectedSystem.location_description;
    const floorToDelete = selectedSystem.location_floor;

    const deleteInfoPopUp = {
        title: POP_UPS_TRANSLATION.DELETE_SYSTEM,
        subtitle: POP_UPS_TRANSLATION.DELETE_SYSTEM_CONFIRMATION,
        parentSystemId: selectedSystem.parent_system_id,
        info: [
            { label: POP_UPS_TRANSLATION.SYSTEM_NAME, value: systemNameToDelete &&( typeof (systemNameToDelete) === 'object' ? systemNameToDelete.name : systemNameToDelete) },
            { label: POP_UPS_TRANSLATION.LOCATION_DESCRIPTION, value: locationDescriptionToDelete && locationDescriptionToDelete },
            { label: POP_UPS_TRANSLATION.FLOOR, value: floorToDelete && floorToDelete },
        ]
    }
    return (
        <div className='system__container'>
            <div className={componentFor === 'add-order' ? 'systems__list--addOrder' : 'systems__list'}>
                {
                    accordionDataTree.length ? accordionDataTree.map((accordionData, index) => {
                        return <Fragment key={index}>
                            <AccordionMain
                                data={accordionData.data}
                                systemId={accordionData.id}
                                view={props.view}
                                onClickHandler={onClickHandler}
                                popupClickHandlers={popupClickHandlers}
                                rowIndex={index}
                            />
                        </Fragment>
                    }) : view !== 'mobile' ? <EmptyList name={'system'} /> : <TabEmptyLanding title={'system'} />
                }
        </div>
            <div className={showPopUp ? 'popUp__background' : ""}>
                {
                    showPopUp ?
                        <>
                            <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
                                <DeletePopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    data={deleteInfoPopUp}
                                    view={view === 'desktop-popup' ? 'desktop' : view}
                                    id={systemId}
                                    layer={currentLayer}
                                    popUpFor={'system'}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.EDIT_SYSTEM}
                                    view={view}
                                    mode={'edit'}
                                    setParentSystemId={setParentSystemId}
                                    from="e"
                                    customerId={customer._id}
                                    updateSystems={updateSystems}
                                    // layer={layer}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
                                <AddSystemPopUp
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    name={POP_UPS_TRANSLATION.ADD_A_SYSTEM}
                                    layer={currentLayer}
                                    addSystemActionLevel={addSystemActionLevel}
                                    view={view}
                                    parentSystemId={parentSystemId}
                                    setParentSystemId={setParentSystemId}
                                    customerId={customer._id}
                                    // setIsNewSystemSubmitted={setIsNewSystemSubmitted}
                                />
                            </div>
                            <div style={{ visibility: currentPopup && currentPopup === 'measurement' ? 'visible' : 'hidden' }}>
                                <AddMeasurements
                                    showPopUp={showPopUp}
                                    handleShowPopUp={handleShowPopUp}
                                    view={view === 'mobile' ? view : 'desktop'}
                                />
                            </div>
                        </> : null
                }
            </div>
            {
                props.view === 'desktop-popup' ? 
                <Grid item xs={12} className='fileTab__btnRow'>
                    <Grid item xs={6} className='fileTab__btnRow-right'>
                        <Button data={systemTabPopUpBtn.save} />
                        &nbsp;
                        &nbsp;
                            <Button data={systemTabPopUpBtn.cancel} handleClick={() => closePopup()} />
                    </Grid>

                        <Grid item xs={6} className='fileTab__btnRow-left'>
                            <Button
                                data={systemTabPopUpBtn.addSystem}
                                // handleShowPopUp={() => onClickHandler('Add System')} 
                                handleClick={() => {
                                    setCurrentPopup('add');
                                    handleShowPopUp()
                                }}
                            />
                            {
                                componentFor === 'add-order' ?
                                    <>
                                        &nbsp;
                                        &nbsp;
                                        <Button data={systemTabDesktopBtn.uploadExcel} handleClick={() => handleShowPopUp()} />
                                    </> : null
                            }
                        </Grid>
                </Grid> 
                : <Grid item xs={12} className='fileTab__btnRow--desktop'>
                        <Button data={systemTabDesktopBtn.uploadExcel} handleClick={() => handleShowPopUp()} />
                    &nbsp;
                    &nbsp;
                        <Button
                            data={systemTabDesktopBtn.addSystem}
                            // handleShowPopUp={() => onClickHandler('Add System')} 
                            handleClick={() => {
                                setCurrentPopup('add');
                                handleShowPopUp()
                                setCurrentLayer(1);
                            }}
                        />
                </Grid>
            }
        </div>
    )
}
