import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../Buttons/Button';
import Input from '../../../InputFields/Input';
import SelectInput from '../../../InputFields/SelectInput/SelectInput';
import "./AddSystemPopUp.scss";
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { systemActionsCreator } from '../../../../../actions/customer/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSystemsRelatedToLayer } from '../../../../../hooks/useGetSystemsRelatedToLayer';
import { SET_SYSTEMS_FOR_ACCORDION } from '../../../../../constants/customer/systemConstants';
import { add, cancel, addSystemInputs, addMobile, cancelMobile, addDesktop, cancelDesktop } from "./AddSystemPopUpStatic";
import { generalPostRequest } from '../../../../../functions/api/general'


export default function AddSystemPopUp(props) {
    const { handleShowPopUp, view, name, mode, layer, from,
        parentSystemTemplateId,
        parentSystemId, setParentSystemId, customerId, selected_process } = props;
        console.log("setParentSystemId, setParentSystemId", parentSystemId, setParentSystemId, "from", from, layer, props)

    const selectedSystem = useSelector((state) => state.customerSystems.singleSystem);
    console.log("selectedSystem -------", selectedSystem)
    const systemNameToEdit = selectedSystem?.system_name;
    const locationDescriptionToEdit = selectedSystem?.location_description;
    const floorToEdit = selectedSystem?.location_floor;
    const parentSystemIdForEditSystem = selectedSystem?.parent_system_id;
    const layerForEditSystem = selectedSystem?.layer;
    const systemIdForEditSystem = selectedSystem?.system_id;


    const [inputValue, setInputValue] = useState({
        systemName: mode === 'edit' ? systemNameToEdit && typeof (systemNameToEdit) === 'object' ? systemNameToEdit.name : systemNameToEdit : '',
        systemNameError: false,
        locationDescription: mode === 'edit' ? locationDescriptionToEdit && locationDescriptionToEdit : '',
        locationDescriptionError: false,
        floor: mode === 'edit' ? floorToEdit && floorToEdit : '',
        floorError: false,
    });

    const templateSystems = useGetSystemsRelatedToLayer(layer);

    const systemSelectionOptions = templateSystems.map((systemOption) => {
        return {
            id: systemOption._id,
            name: systemOption.description
        }
    })

    const dispatch = useDispatch();


    const handleAddSystem = async (systemBody) => {
        await generalPostRequest(systemBody, `/system/customer-page/add-new-actual-system`)
      }

    const handleChange = (name, value) => {
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const submitForm = async () => {
        console.log("inputValue", inputValue)
        if (mode === 'edit') {
            const newSystemToEdit = {
                systemName: inputValue.systemName,
                locationDescription: inputValue.locationDescription,
                floor: inputValue.floor,
                systemId: systemIdForEditSystem,
                parentId: parentSystemIdForEditSystem,
                layer: layerForEditSystem
            }
            dispatch(systemActionsCreator.editSystem(newSystemToEdit))
        } else {
            const newSystemToAdd = {
                parentId: parentSystemId ? parentSystemId : null,
                systemName: inputValue.systemName,
                locationDescription: inputValue.locationDescription,
                floor: inputValue.floor,
                layer: layer && parentSystemId ? layer  : 1,
            }
            
            console.log("layer ...", layer, parentSystemId)
            await handleAddSystem({
                "system_id": inputValue.systemName.id,
                "actual_system_name":  inputValue.systemName.name,
                "location_floor": inputValue.floor,
                "location_description": inputValue.locationDescription,
                "system_type": "",
                "parent_system_id": parentSystemId ? parentSystemId : null,
                "customer_id": customerId,
                "layer": layer && parentSystemId ? layer : 1,
                order_id: "63ac4b587fd3fa5e2c7b1e37",

            })
            dispatch(systemActionsCreator.addSystem(newSystemToAdd))

        }
        handleShowPopUp();
        if(view === 'mobile'){
            dispatch(systemActionsCreator.showSystemsPopup(false))
        }
        // dispatch({ type: SET_SYSTEMS_FOR_ACCORDION, payload: systemsAllData});
        if (setParentSystemId) {
            setParentSystemId(()=> null);
        }
        
    }

    // useEffect(() => {
    //     dispatch(systemActionsCreator.selectedSystemBySystemId(systemId))
    // }, [systemId])

    const addSystemInputsToSend = {
        ...addSystemInputs,
        systemName: {
            ...addSystemInputs.systemName,
            selectOptions: systemSelectionOptions,
            //[{ name: 'system 1', id: 1 }, { name: 'system 2', id: 2 }, { name: 'system 3', id: 3 }],
            handleChange: handleChange,
            value: inputValue.systemName || { name: '' },
            placeholder: mode === 'edit' ? (typeof (systemNameToEdit) === 'object' ? systemNameToEdit.name : systemNameToEdit) : POP_UPS_TRANSLATION.CHOOSE,
            error: inputValue.systemNameError,
            errorText: inputValue.systemNameError && 'system has error'
        },
        locationDescription: {
            ...addSystemInputs.locationDescription,
            onChange: handleChange,
            value: inputValue.locationDescription,
            placeholder: mode === 'edit' ? locationDescriptionToEdit : POP_UPS_TRANSLATION.ENTER,
            error: inputValue.locationDescriptionError,
            errorText: inputValue.locationDescriptionError && 'location has error'
        },
        floor: {
            ...addSystemInputs.floor,
            onChange: handleChange,
            value: inputValue.floor,
            placeholder: mode === 'edit' ? floorToEdit : POP_UPS_TRANSLATION.ENTER,
            error: inputValue.floorError,
            errorText: inputValue.floorError && 'floor has error'
        }
    }

    return (
            <Grid className='addSystem__main' style={{ left: view == 'desktop' || view == 'desktop-popup' && '15%' }}>
                <Grid>
                    <h4 className='addSystem__title'>{name}</h4>
                </Grid>
                <Grid className='addSystem__uploadedInfo'>
                    {
                        selected_process?.customerName  ?   <Grid item xs={12} className='addSystem__uploadedInfo-row'>
                                <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.CUSTOMER_NAME}</span></Grid>
                                <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{selected_process?.customerName}</span></Grid>
                            </Grid> : null 
                    }

                    {
                        selected_process?.orderNumber ? <Grid item xs={12} className='addSystem__uploadedInfo-row'>
                             <Grid item xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.ORDER_NUMBER}</span></Grid>
                             <Grid item xs={6} className='addSystem__uploadedInfo-value'><span>{selected_process?.orderNumber}</span></Grid>
                         </Grid> : null
                    }
                   
                </Grid>
                <Grid sx={12} className='addSystem__inputsFields'>
                    <Grid>
                    <SelectInput data={addSystemInputsToSend.systemName} view={view} />
                    </Grid>
                    <Grid className='addSystem__inputsRow'>
                        <Grid item xs={12} md={7}>
                        <Input data={addSystemInputsToSend.locationDescription} view={view} />
                        </Grid>
                        <Grid item xs={12} md={5} className='addSystem__inputsLeft'>
                        <Input data={addSystemInputsToSend.floor} view={view} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='addSystem__emptyBox'>

                </Grid>
                {
                    view === 'mobile' ? <Grid sx={12} className={`addSystem__btn__container`}>
                    <Button data={addMobile} handleClick={() => submitForm()} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    <Button data={cancelMobile} handleClick={() => {
                        handleShowPopUp()
                        dispatch(systemActionsCreator.showSystemsPopup(false))
                    }} />
                    </Grid>
                        : view === 'desktop' || view === 'desktop-popup' ? <Grid sx={12} className='addSystem__btn__container'>
                        <Button data={addDesktop} handleClick={() => submitForm()} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                        <Button data={cancelDesktop} handleClick={() => handleShowPopUp()} />
                        </Grid>
                            : <Grid sx={12} className='addSystem__btn__container'>
                            <Button data={add} handleClick={() => handleShowPopUp()} />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                            <Button data={cancelDesktop} handleClick={() => handleShowPopUp()} />
                            </Grid>
                }

            </Grid>
    )    
}
